import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
//import { PrimengTranslationService } from '../../services/primeng-translation-service';

interface Country {
  name: string;
  iso2: string;
}

@Component({
  selector: 'app-language-button',
  templateUrl: './language-button.component.html',
  standalone: true,
  imports: [CommonModule, MenuModule, TranslateModule],
})
export class LanguageButtonComponent implements OnInit {
  countries: MenuItem[] = [];
  selectedCountry: string = ''; 

  @Output() refreshData: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private router: Router
  ) {
    this.selectedCountry = (this.translate.currentLang || this.translate.getDefaultLang() || 'pl').toUpperCase();
  }

  ngOnInit() {
    this.initElement();
  }

  onLanguageChange(iso2: string) {
    this.selectedCountry = iso2.toUpperCase();
    this.translate.use(this.selectedCountry.toLowerCase());
    localStorage.setItem('appLang', this.selectedCountry.toLowerCase());
    this.refreshElement();
  }

  initElement() {
    let currentLanguage = this.translate.currentLang || this.translate.getDefaultLang();
    if(currentLanguage===undefined){
      currentLanguage='pl';
    }
  
    this.http.get<Country[]>('assets/countries/' + currentLanguage + '.json').subscribe((data) => {
      this.countries = data
        .filter((country) => country.iso2.toUpperCase() !== this.selectedCountry.toUpperCase())
        .map((country) => {
          const flagCode = country.iso2.toLowerCase() === 'en' ? 'gb' : country.iso2.toLowerCase();
          return {
            label: country.name,
            icon: 'assets/flags/4x3/' + flagCode + '.svg',
            command: () => this.onLanguageChange(country.iso2),
          };
        });
  
      this.selectedCountry = currentLanguage.toUpperCase();
    });
  }
  
  refreshElement() {
    this.http.get<Country[]>('assets/countries/' + this.selectedCountry.toLowerCase() + '.json').subscribe((data) => {
      this.countries = data
        .filter((country) => country.iso2.toUpperCase() !== this.selectedCountry.toUpperCase())
        .map((country) => {
          const flagCode = country.iso2.toLowerCase() === 'en' ? 'gb' : country.iso2.toLowerCase();
          return {
            label: country.name,
            icon: 'assets/flags/4x3/' + flagCode + '.svg',
            command: () => this.onLanguageChange(country.iso2),
          };
        });
  
      this.reloadPage();
    });
  }
  

  reloadPage() {
    this.refreshData.emit();
  
    const currentUrl = this.router.url;
    
    const urlTree = this.router.parseUrl(currentUrl);
    const path = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
    const queryParams = urlTree.queryParams;
  
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([path], { queryParams });
    });
  }
  
  
}
