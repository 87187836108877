import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from '../site/login/login.component';
import { AddCredentialsComponent } from './credentials/add-credentials/add-credentials.component';
import { CredentialsListComponent } from './credentials/list/credentials-list.component';
import { DashboardComponent } from './dashboard.component';
import { FirstConfigurationComponent } from './first-configuration/first-configuration.component';
import { HelpComponent } from './help/help.component';
import { EditCompanyInvoiceCardComponent } from './profile/edit-company-invoice-card/edit-company-invoice-card.component';
import { EditCompanyProfileCardComponent } from './profile/edit-company-profile-card/edit-company-profile-card.component';
import { EditPasswordCardComponent } from './profile/edit-password-card/edit-password-card.component';
import { ProfileComponent } from './profile/profile.component';
import { StoreListComponent } from './store/list/list.component';
import { AddDialogComponent } from './users/add-dialog/add-dialog.component';
import { UsersComponent } from './users/users/users.component';
import { CommonModule as _common_module_ } from '../common/common.module'
import { RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { PickListModule } from 'primeng/picklist';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { StepsModule } from 'primeng/steps';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { CreateCompanyInstructionsComponent } from './ui/create-company-instructions/create-company-instructions.component';
import { SidebarModule } from 'primeng/sidebar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { SidebarTileComponent } from './ui/sidebar-tile/sidebar-tile.component';
import { SkeletonModule } from 'primeng/skeleton';
import { ShopPackageItemComponent } from './store/components/shop-package-item/shop-package-item.component';
import { CarouselModule } from 'primeng/carousel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InvoiceFormDialogComponent } from './store/components/invoice-form-dialog/invoice-form-dialog.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChipModule } from 'primeng/chip';
import { CommonModule } from '@angular/common';
import { PackagesProfileListComponent } from './profile/packages-list/packages-profile-list.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputNumberModule } from 'primeng/inputnumber';
import { ListComponent } from './companies/list/list.component';
import { CreateCompanyDialog } from './companies/create/create.component';
import { GenericTableComponent } from '@mobiletract/ngx-generic-table';
import { BlockUIModule } from 'primeng/blockui';
import { TabViewModule } from 'primeng/tabview';
import { CompanyInfoComponent } from './companies/components/details/details.component';
import { CompanyDetailsComponent } from './companies/details/details.component';
import { LanguageButtonComponent } from './ui/language-button/language-button.component';


@NgModule({ exports: [],
    declarations: [
        LoginComponent,
        DashboardComponent,
        ProfileComponent,
        StoreListComponent,
        UsersComponent,
        AddDialogComponent,
        CredentialsListComponent,
        AddCredentialsComponent,
        HelpComponent,
        EditCompanyProfileCardComponent,
        EditPasswordCardComponent,
        FirstConfigurationComponent,
        EditCompanyInvoiceCardComponent,
        ChangePasswordComponent,
        SubscriptionComponent,
        CreateCompanyInstructionsComponent,
        SidebarTileComponent,
        ShopPackageItemComponent,
        InvoiceFormDialogComponent,
        PackagesProfileListComponent,
        ListComponent,
        CreateCompanyDialog,
        CompanyInfoComponent,
        CompanyDetailsComponent
    ], imports: [CommonModule,
        BrowserModule,
        LayoutModule,
        RouterModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AvatarModule,
        ButtonModule,
        MultiSelectModule,
        PickListModule,
        InputTextModule,
        PasswordModule,
        ConfirmDialogModule,
        GoogleTagManagerModule,
        StepsModule,
        DropdownModule,
        CheckboxModule,
        SidebarModule,
        BreadcrumbModule,
        TableModule,
        SkeletonModule,
        CarouselModule,
        RadioButtonModule,
        SelectButtonModule,
        DynamicDialogModule,
        ButtonModule,
        ConfirmPopupModule,
        FontAwesomeModule,
        ChipModule,
        InputSwitchModule,
        InputNumberModule,
        BlockUIModule,
        TabViewModule,
        _common_module_,
        // Mobiletract
        GenericTableComponent,
        LanguageButtonComponent
        ], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class DashboardModule { }
