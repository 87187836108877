<div class="flex items-center justify-center h-screen bg-gradient-to-br from-gray-200 to-gray-500 relative overflow-hidden">
  <div class="flex flex-col items-center text-center">
    <img src="assets/logo.png" alt="Logo" class="w-36 h-36 mb-20 rounded-full border-4 border-dark-green-300 shadow-lg" />
    <div class="relative bg-white shadow-xl rounded-xl p-10 text-center animate-fadeIn flex flex-col items-center">
      
      <i class="pi pi-exclamation-triangle text-red-500 text-5xl animate-bounce"></i>
  
      <h2 class="text-2xl font-semibold mt-5">
        {{ title }}
      </h2>
  
      <p class="text-gray-500 mt-3 max-w-sm">
        {{ message }}
      </p>
  
      <button pButton type="button" label="Powrót na stronę główną" icon="pi pi-home"
        class="mt-6 p-button p-button-primary" (click)="goHome()">
      </button>
    </div>
  </div>

</div>
