import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
})
export class ErrorPageComponent {
  currentRoute: string;
  title: string;
  message: string;

  constructor(private router: Router) {
    this.currentRoute = this.router.url;
    this.setErrorMessage();
  }

  private setErrorMessage(): void {
    if (this.currentRoute === '/404') {
      this.title = 'Ups! Nie znaleziono treści';
      this.message = 'Treść, której szukasz, mogła zostać usunięta lub adres jest niepoprawny.';
    } else {
      this.title = 'Błąd serwera';
      this.message = 'Wystąpił błąd po stronie serwera. Spróbuj ponownie później.';
    }
  }

  goHome(): void {
    this.router.navigate(['/'], { replaceUrl: true });

  }
}
