import { TokenService } from 'src/app/services/token/token.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { filter, shareReplay } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { FirstConfigurationComponent } from './first-configuration/first-configuration.component';
import { Profile } from '../services/bdoservice/users/models/profile.model';
import { KeycloakService } from 'keycloak-angular';
import packageInfo from '../../../package.json';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ThemeService } from '../services/theme.service';
import { PackageInfo } from './ui/sidebar-tile/package-info.model';
import { AccountService } from '../services/account.service';
import * as Sentry from "@sentry/angular-ivy";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',

  providers: [ConfirmationService, DialogService]
})

export class DashboardComponent implements OnInit {
  appVersion = '0.0.0';
  isInitializing = true;

  isHandset = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay(1)
    );

  profile: Profile;

  sidebarVisible = false;
  sidebarWidth = 320;
  isResizing = false;
  isCollapsed = false; // State of the sidebar

  readonly MIN_WIDTH = 25;
  readonly MAX_WIDTH = 320;

  activePackage: PackageInfo | null = null;

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
    this.sidebarWidth = this.isCollapsed ? this.MIN_WIDTH : this.MAX_WIDTH;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isResizing) {
      // Calculate the new width
      let newWidth = event.clientX;

      // Enforce minimum and maximum bounds
      if (newWidth < this.MIN_WIDTH) {
        newWidth = this.MIN_WIDTH;
        this.isCollapsed = true;
      } else if (newWidth > this.MAX_WIDTH) {
        newWidth = this.MAX_WIDTH;
        this.isCollapsed = false;
      }

      // Update the sidebar width
      this.sidebarWidth = newWidth;
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp() {
    if (this.isResizing) {
      this.isResizing = false;
    }
  }

  startResizing(event: MouseEvent) {
    event.preventDefault();
    this.isResizing = true;
  }

  temporarilyShowSidebar() {
    if (this.isCollapsed) {
      this.sidebarWidth = this.MAX_WIDTH;
    }
  }

  onMouseLeaveSidebar() {
    this.showToggleButton = this.isCollapsed;

    if (this.isCollapsed) {
      this.sidebarWidth = this.MIN_WIDTH;
    }
  }

  menuItems: MenuItem[] = [];

  scrolled = false;
  firstConfigurationDialog: DynamicDialogRef | undefined;
  showToggleButton = false;

  @HostListener('window:scroll', ['$event']) onWindowScroll() {
    this.scrolled = window.scrollY > 0;
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private readonly dialogService: DialogService,
    private router: Router,
    private readonly keycloak: KeycloakService,
    private readonly gtmService: GoogleTagManagerService,
    protected readonly themeService: ThemeService,
    private readonly messageService: MessageService,
    private readonly accountService: AccountService,
    private readonly tokenService: TokenService,

    private keycloakService: KeycloakService
  ) {

  }

  changeExpandSubmenu(item) {
    item.showChildrens = !item.showChildrens;
  }

  refreshPackageInfo() {
    this.accountService.loadProfile();
  }

  getFullName(): string {
    return `${this.profile?.firstName} ${this.profile?.lastName}`;
  }

  toggleNightMode(): void {
    this.themeService.toggleDarkMode();
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnInit(): void {

    const companyContext = this.tokenService.getCompanyContext();

    this.initMenu();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      const gtmTag = {
        event: 'page',
        pageName: event.url
      };
      this.gtmService.pushTag(gtmTag);
    });

    this.isHandset$.subscribe(isHandset => {
      this.isHandset = isHandset;
    });

    this.accountService.activePackage$.subscribe(activePackage => {
      if(activePackage){
        this.activePackage = new PackageInfo(
          activePackage.name,
          activePackage.operationCount,
          100,
          new Date(activePackage.endDate),
          activePackage.activated,
        );
      }
    });

    this.accountService.profile$.subscribe(profileState => {
      if (profileState?.requireFirstConfiguration) {
        this.showFirstConfiguration();
        this.isInitializing = false;
      }

      if (profileState?.data) {
        if (this.keycloak.getUsername() !== companyContext?.username) {
          this.tokenService.removeCompanyContext();
        }

        this.profile = profileState.data;
        this.isInitializing = false;
      }

    });

    this.accountService.loadProfile();

    this.appVersion = packageInfo.version;

    const keycloakInstance = this.keycloak.getKeycloakInstance();

    keycloakInstance.onTokenExpired = () => {

      keycloakInstance.updateToken(30).then(() => {}).catch(() => {
        console.error('failed to get a new token');
        this.redirectToLogin()
      });

      keycloakInstance.onAuthError = (error) => {
        if (error.error === "invalid_token" || error.error === "access_denied") {
          console.log("Token wygasł lub jest nieprawidłowy. Przekierowanie do strony logowania.");
          keycloakInstance.logout();
          this.redirectToLogin()
        }
      };
    }

  }

  navigateToSettings() {
    this.router.navigate(['dashboard/profile']);
  }

  initMenu() {
    const roles = this.keycloak.getUserRoles();
    this.menuItems = [
      { label: 'Lista podmiotów', icon: 'pi pi-building', routerLink: ['/dashboard/companies'], visible: this.hasRole('COMPANY', roles) },
      {
        label: 'Karty odpadów',
        icon: 'pi pi-folder-open',
        visible: false,
        items: [
          { label: 'Lista KPO/KPOK', icon: 'pi pi-list', routerLink: ['/dashboard/subscription'] },
          { label: 'Nowa KPO/KPOK', icon: 'pi pi-plus-circle', routerLink: ['/dashboard/subscription'] }
        ],
      },
      {
        label: 'Karty odpadów',
        icon: 'pi pi-folder',
        items: [
          { label: 'Lista KPO/KPOK', icon: 'pi pi-list', routerLink: ['/dashboard/waste-cards/index'] },
          { label: 'Nowa KPO/KPOK', icon: 'pi pi-plus-circle', routerLink: ['/dashboard/waste-cards/planned'] }
        ],
      },
      {
        label: 'Ewidencja odpadów',
        icon: 'pi pi-book',
        routerLink: ['/dashboard/waste-register'],
        items: [
          { label: 'Lista KEO/KEOK', icon: 'pi pi-list', routerLink: ['/dashboard/waste-register/record-search'] },
          { label: 'Nowy wpis', icon: 'pi pi-plus-circle', routerLink: ['/dashboard/waste-register/search'] }
        ],
        expanded: false
      },
      { label: 'Opakowania', icon: 'pi pi-inbox', routerLink: ['/dashboard/packaging'], routerLinkActiveOptions: { exact: true }, visible: this.hasPackagingRole()},
      { label: 'Opakowania', icon: 'pi pi-inbox', badge: 'Brak dostępu', routerLink: ['/dashboard/subscription'], visible: !this.hasPackagingRole()},
      { label: 'Raporty', icon: 'pi pi-table', routerLink: ['/dashboard/subscription'], routerLinkActiveOptions: { exact: true }, visible: !this.hasRole('REPORTS', roles) && this.hasRole('COMPANY', roles) },
      { label: 'Raporty', icon: 'pi pi-table', routerLink: ['/dashboard/reports/index'], routerLinkActiveOptions: { exact: true }, visible: this.hasRole('REPORTS', roles) && this.hasRole('COMPANY', roles) },
      { label: 'Sprawozdania', icon: 'pi pi-file', visible: this.hasRole('ADMIN', roles) && this.hasRole('COMPANY', roles),
        items: [
          { 'label': 'Opakowania', icon: 'pi pi-file', routerLink: ['/dashboard/productreport/list'], routerLinkActiveOptions: { exact: true } },
          { 'label': 'Opakowania (admin)', icon: 'pi pi-file', routerLink: ['/dashboard/productreport/admin/companies'], routerLinkActiveOptions: { exact: true } }
        ],
       },
      { label: 'Użytkownicy', icon: 'pi pi-users', routerLink: ['/dashboard/users'], routerLinkActiveOptions: { exact: true }, visible: this.hasRole('COMPANY', roles) },
      { label: 'Sklep', icon: 'pi pi-shopping-cart', routerLink: ['/dashboard/store/plans'], routerLinkActiveOptions: { exact: true }, visible: this.hasRole('COMPANY', roles) },
      { label: 'Pomoc', icon: 'pi pi-heart', routerLink: ['/dashboard/help'], routerLinkActiveOptions: { exact: true }, },
    ];
  }

  hasRole(role: string, availableRoles: string[]): boolean {
    return availableRoles.indexOf(role) > -1
  }

  hasPackagingRole(): boolean {
    return this.hasClientRole('access', 'packaging-management-service') || this.hasClientRole('access_write_only', 'packaging-management-service');
  }

  hasClientRole(role: string, clientName: string): boolean {
    return this.keycloak.isUserInRole(role, clientName);
  }

  redirectToLogin() {
    this.messageService.add({ severity: 'error', summary: 'Błąd', detail: 'Wystąpił błąd podczas logowania. Spróbuj ponownie.' });
    this.router.navigate(['login']).then(() => console.log('redirect to login'));
  }

  showFirstConfiguration() {
    this.firstConfigurationDialog = this.dialogService.open(FirstConfigurationComponent, {
      closable: false,
      header: 'Witamy w BDO mobile',
      width: this.isHandset ? '100vw' : '50vw',
    });
    this.isInitializing = false;
  }

  logout(): void {
    localStorage.clear();
    Sentry.setUser(null);
    this.keycloak.logout();
  }


}
