import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { loadCompanies } from './common/ui/company-context-select/company-context.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',

  providers: [MessageService]
})
export class AppComponent {
  title = 'bdo-webpanel';

  constructor(
    private translate: TranslateService,
    private config: PrimeNGConfig,
    private readonly gtmService: GoogleTagManagerService,
    @Inject(DOCUMENT) private document: Document,
    private store: Store,  
  ){
    this.store.dispatch(loadCompanies());
    this.setTranslations();

    this.gtmService.addGtmToDom();
    this.loadTheme();
      }

      initLang() {
        const savedLang = localStorage.getItem('appLang');
        const browserLang = this.translate.getBrowserLang();
        const supportedLangs = ['pl', 'en', 'de','es','fr'];
        
        let defaultLang = 'pl'; 
    
        if (savedLang && supportedLangs.includes(savedLang)) {
          defaultLang = savedLang;
        } else if (browserLang && supportedLangs.includes(browserLang)) {
          defaultLang = browserLang;
        }
    
        this.translate.setDefaultLang(defaultLang);
        this.translate.use(defaultLang);
      }

  loadTheme() {
    // Determine the theme based on user preference or default
    const theme = 'light'; // or 'dark', or from user settings

    // Create a new link element for the theme
    const themeLink = this.document.createElement('link');
    themeLink.id = 'app-theme';
    themeLink.rel = 'stylesheet';
    themeLink.href = `assets/themes/material/${theme}/theme.css`;

    // Append the new theme link at the end of the <head>
    this.document.head.appendChild(themeLink);
  }

  setTranslations(){
    this.config.setTranslation({
      // Podstawowe tłumaczenia
      accept: 'Akceptuj',
      reject: 'Odrzuć',
      // Tłumaczenia dla filtrów
      startsWith: 'Zaczyna się od',
      contains: 'Zawiera',
      notContains: 'Nie zawiera',
      endsWith: 'Kończy się na',
      equals: 'Równe',
      notEquals: 'Nie równe',
      noFilter: 'Brak filtra',
      lt: 'Mniej niż',
      lte: 'Mniej niż lub równe',
      gt: 'Więcej niż',
      gte: 'Więcej niż lub równe',
      is: 'Jest',
      isNot: 'Nie jest',
      before: 'Przed',
      after: 'Po',
      dateIs: 'Data to',
      dateIsNot: 'Data to nie',
      dateBefore: 'Data przed',
      dateAfter: 'Data po',
      clear: 'Wyczyść',
      apply: 'Zastosuj',
      matchAll: 'Dopasuj wszystko',
      matchAny: 'Dopasuj dowolne',
      addRule: 'Dodaj regułę',
      removeRule: 'Usuń regułę',
      // Tłumaczenia dla przycisków
      choose: 'Wybierz',
      upload: 'Prześlij',
      cancel: 'Anuluj',
      // Tłumaczenia dla dat
      dayNames: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
      dayNamesShort: ["Niedz.", "Pon.", "Wt.", "Śr.", "Czw.", "Pt.", "Sob."],
      dayNamesMin: ["N", "Pn", "Wt", "Śr", "Cz", "Pt", "Sb"],
      monthNames: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
      monthNamesShort: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
      dateFormat: 'dd/mm/yy',
      firstDayOfWeek: 1,
      today: 'Dzisiaj',
      weekHeader: 'Tydz',
      // Tłumaczenia dla siły hasła
      weak: 'Słabe',
      medium: 'Średnie',
      strong: 'Mocne',
      passwordPrompt: 'Wprowadź hasło',
      // Tłumaczenia dla komunikatów
      emptyMessage: 'Brak wyników',
      emptyFilterMessage: 'Brak wyników dla filtra',
      pending: 'Oczekuje',
      chooseYear: 'Wybierz rok',
      chooseMonth: 'Wybierz miesiąc',
      chooseDate: 'Wybierz datę',
      prevDecade: 'Poprzednia dekada',
      nextDecade: 'Następna dekada',
      prevYear: 'Poprzedni rok',
      nextYear: 'Następny rok',
      prevMonth: 'Poprzedni miesiąc',
      nextMonth: 'Następny miesiąc',
      prevHour: 'Poprzednia godzina',
      nextHour: 'Następna godzina',
      prevMinute: 'Poprzednia minuta',
      nextMinute: 'Następna minuta',
      prevSecond: 'Poprzednia sekunda',
      nextSecond: 'Następna sekunda',
      am: 'AM',
      pm: 'PM',
      fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      searchMessage: 'Dostępnych wyników: {0}',
      selectionMessage: 'Wybrane elementy: {0}',
      emptySelectionMessage: 'Brak wybranych elementów',
      emptySearchMessage: 'Brak wyników wyszukiwania',
      aria: {
        trueLabel: 'Prawda',
        falseLabel: 'Fałsz',
        nullLabel: 'Nie wybrano',
        star: '1 gwiazdka',
        stars: '{star} gwiazdki',
        selectAll: 'Wybierz wszystkie',
        unselectAll: 'Odznacz wszystkie',
        close: 'Zamknij',
        previous: 'Poprzedni',
        next: 'Następny',
        navigation: 'Nawigacja',
        scrollTop: 'Przewiń do góry',
        moveTop: 'Przenieś na górę',
        moveUp: 'Przenieś wyżej',
        moveDown: 'Przenieś niżej',
        moveBottom: 'Przenieś na dół',
        moveToTarget: 'Przenieś do celu',
        moveToSource: 'Przenieś do źródła',
        moveAllToTarget: 'Przenieś wszystko do celu',
        moveAllToSource: 'Przenieś wszystko do źródła',
        pageLabel: 'Strona {page}',
        firstPageLabel: 'Pierwsza strona',
        lastPageLabel: 'Ostatnia strona',
        nextPageLabel: 'Następna strona',
        prevPageLabel: 'Poprzednia strona',
        rowsPerPageLabel: 'Wierszy na stronę',
        jumpToPageDropdownLabel: 'Skocz do strony',
        jumpToPageInputLabel: 'Skocz do strony',
        selectRow: 'Wybierz wiersz',
        unselectRow: 'Odznacz wiersz',
        expandRow: 'Rozwiń wiersz',
        collapseRow: 'Zwiń wiersz',
        showFilterMenu: 'Pokaż menu filtrów',
        hideFilterMenu: 'Ukryj menu filtrów',
        filterOperator: 'Operator filtru',
        filterConstraint: 'Ograniczenie filtru',
        editRow: 'Edytuj wiersz',
        saveEdit: 'Zapisz edycję',
        cancelEdit: 'Anuluj edycję',
        listView: 'Widok listy',
        gridView: 'Widok siatki',
        slide: 'Pokaz slajdów',
        slideNumber: 'Slajd {slideNumber}',
        zoomImage: 'Powiększ obraz',
        zoomIn: 'Powiększ',
        zoomOut: 'Pomniejsz',
        rotateRight: 'Obróć w prawo',
        rotateLeft: 'Obróć w lewo'
      }
    });
  }
}
